<template>
  <div>
    <br />
    <div class="col-sm-12 text-right">
      <h1>COI</h1>
    </div>
    <br />

    <DataGrid :config="dataGridConfig" :data="layouts" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones">
      <div class="row">
        <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_layouts">Recargar</button></div>
        <div class="col-sm-2"><button class="btn secondary-btn" @click="crear_layout_model=true">Generar layout</button></div>
      </div>
    </DataGrid>

    <Modal v-if="crear_layout_model" :options="{width: '40vw'}">
      <form @submit.prevent="generar_layout">
        <div class="title">Generar layout para COI</div>
        <div class="body">
          <div class="row form-group">
            <label for="layout.fecha_inicio" class="col-form-label col-sm-3">Fecha inicio</label>
            <div class="col-sm-9"><input v-model="layout.fecha_inicio" type="date" name="layout.fecha_inicio" id="layout.fecha_inicio" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="layout.fecha_final" class="col-form-label col-sm-3">Fecha Final</label>
            <div class="col-sm-9"><input v-model="layout.fecha_final" type="date" name="layout.fecha_final" id="layout.fecha_final" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="layout.tipo_poliza" class="col-form-label col-sm-3">Tipo de poliza</label>
            <div class="col-sm-9">
              <select v-model="layout.tipo_poliza" name="layout.tipo_poliza" id="layout.tipo_poliza" class="form-control">
                <option value="egresos">Egresos</option>
                <option value="gastos">Gastos</option>
                <option value="viaticos">Viaticos</option>
                <option value="todos">Todos</option>
              </select>  
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn">Crear</button></div>
            <div class="col-sm-3"><button type="button" class="btn danger-btn" @click="cancelar_generar_layout">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Modal from '@/components/Modal'

  import api from '@/apps/tesoreria/api/coi'

  export default {
    components: {
      DataGrid, Modal
    }
    ,data: function() {
      return {
        dataGridConfig: {
          name: 'coi'
          ,select_items: false
          ,cols: {
            usuario: 'Usuario'
            ,no_pagos: 'No pagos'
            ,fecha_inicio: 'Fecha de inicio'
            ,fecha_fin: 'Fecha final'
            ,tipo_poliza: 'Tipo'
            ,estatus: 'Estatus'
            ,id: {
              text: 'Descarga'
              ,ordeable: false
            }
          }
          ,filters: {
            cols: {
              id: 'ID'
              ,solicitud_id: 'Solicitud'
              ,nombre: 'Nombre'
              ,created_at: 'Fecha de creación'
            }
          }
          ,paginator: {
            pagina_actual: 1
            ,total_registros: 1
            ,registros_por_pagina: 20
          }
          ,mutators: {
            fecha_inicio: function(val,row,vue) {
              return vue.$moment(val).format('YYYY-MM-DD');
            }
            ,fecha_fin: function(val,row,vue) {
              return vue.$moment(val).format('YYYY-MM-DD');
            }
            ,tipo_poliza(val) {
              return val.charAt(0).toUpperCase() + val.slice(1);
            }
            ,estatus: function(val) {
              switch(val) {
                case 0:
                  return 'En proceso';
                  break;
                case 1:
                  return 'Disponible';
                  break;
              }
            }
            ,created_at: function(val, row, vue) {
              return vue.$moment(val).format('YYYY-MM-DD hh:mm:ss')
            }
            ,id: function(val) {
              return '<button class="btn btn-secondary" id="layout_id_'+val+'" name="layout_btn_download" data-id="'+val+'">Descargar</button>';
            }
          }
        }
        ,options: {
          page: 1
          ,order_col: 'id'
          ,order_dir: 'desc'
          ,limit: 20
          ,filters: []
        }
        ,seleccionar: false
        ,seleccionadas: []
        ,layouts: []
        ,crear_layout_model: false
        ,layout: {
          fecha_inicio: null
          ,fecha_final: null
          ,tipo_poliza: 'egresos'
        }
      }
    }
    ,methods: {
      dataGridActions: function(tipo, data) {
        this.$log.info('tipo', tipo);
        if (tipo == 'options') {
          this.options = data;
          this.obtener_layouts();
        }else {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,obtener_layouts: async function() {
        try {
          let res = (await api.obtener_layouts(this.options)).data;

          this.layouts = res.data;
          this.dataGridConfig.paginator.pagina_actual = res.current_page;
          this.dataGridConfig.paginator.total_registros = res.total;
          this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,generar_layout: async function() {
        try {
          if (this.$moment(this.layout.fecha_final).isBefore(this.layout.fecha_inicio,'day')) 
            return this.$helper.showMessage('Error','La fecha final no puede ser anterior a la fecha de inicio','error','alert');

          let res = (await api.crear_layout(this.layout)).data;
          this.$log.info('res',res);

          this.cancelar_generar_layout();
          this.obtener_layouts();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cancelar_generar_layout: function() {
        this.crear_layout_model = false;
        this.layout = {
          fecha_inicio: null
          ,fecha_final: null
          ,tipo_poliza: 'egresos'
        }
      }
      ,buscar_botones: function() {
        let botones = document.querySelectorAll('button[name="layout_btn_download"]');
        this.$log.info('botones', botones)

        botones.forEach(btn => {
          btn.removeEventListener('click',() => {});
          btn.addEventListener('click', this.descargar_layout);
        });
      }
      ,descargar_layout: async function(e) {
        try {
          let file = (await api.descargar_layout(e.target.dataset.id)).data;
          // this.$log.info('file',file);

          // let urlFile = window.URL.createObjectURL(new Blob([file.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
          const link = document.createElement('a');
          link.href = file;
          // let name = 'layout_coi.xlsx';
          // name = name.substring(name.indexOf('=')+1,name.length);
          // link.setAttribute('download',  name);

          document.body.appendChild(link);

          link.click();
          link.remove();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>